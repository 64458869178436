import type { User } from "./types/auth0Client";
import { setupClient } from "./client";
import { trackUserLoginEvent } from "./reporting";
import {
  hasRecentlyCheckedLogin,
  setRecentlyCheckedLoginCookie,
} from "./cookie";
import { handleAuthCallback } from "./callback";

export const handleLogin = () => {
  setupClient()
    .then(handleAuthCallback)
    .then(async (auth0Client) => {
      /*
       * This call to getTokenSilently will identify whether there is a valid auth0 session.
       * E.g. if the user has logged in on another subdomain, this will authenticate the
       * user on the current subdomain as well.
       *
       * The tv2HasCheckedLogin cookie is used to avoid calling getTokenSilently on every page load.
       */
      if (!hasRecentlyCheckedLogin()) {
        try {
          await auth0Client.getTokenSilently({
            authorizationParams: { redirect_uri: location.origin },
          });
        } catch (_e) {
          // this is expected when there is no valid auth0 session and can be ignored
        } finally {
          setRecentlyCheckedLoginCookie();
        }
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      /*
       * Let the world know that the Auth0 is ready.
       */
      document.dispatchEvent(
        new CustomEvent("tv2-menu:auth0-ready", {
          detail: {
            isReady: true,
          },
        })
      );

      if (!isAuthenticated) {
        document.dispatchEvent(
          new CustomEvent("tv2-menu:auth0-ready", {
            detail: {
              isAuthenticated: false,
              isReady: true,
              userId: null,
            },
          })
        );

        return updateUI();
      }

      const user = await auth0Client.getUser();
      const userId = user?.["https://tv2.no/userId"];
      updateUI(user);
      trackUserLoginEvent("User Authenticated", userId);

      document.dispatchEvent(
        new CustomEvent("tv2-menu:auth0-ready", {
          detail: {
            isAuthenticated,
            isReady: true,
            userId,
          },
        })
      );
    })
    .catch((e) => {
      console.error("auth:", e.message);
    });
};

const updateUI = (user?: User) => {
  const $authBubble = document.getElementById("tv2-menu-auth-bubble");
  const $authLabel = document.getElementById("tv2-menu-auth-label");
  const $authLabelSecondary = document.getElementById(
    "tv2-menu-auth-label--secondary"
  );
  const $loginButton = document.getElementById("tv2-menu-login-btn");
  const $logoutButton = document.getElementById("tv2-menu-logout-btn");

  if ($loginButton) {
    $loginButton.onclick = async function () {
      if (user) {
        const $userProfileContextMenu = document.getElementById(
          "tv2-menu-auth-context-menu"
        );
        $userProfileContextMenu?.classList.toggle("tv2-menu__auth-menu--open");
      } else {
        await window.TV2?.login(
          "Navbar",
          window.lab_metadata?.tags?.sectionTag
        );
      }
    };
  }

  if ($logoutButton) {
    $logoutButton.onclick = window.TV2?.logout;
  }

  if ($authBubble && $authLabel) {
    if (user) {
      $authBubble.classList.add("tv2-menu__auth-bubble--authenticated");
      $authBubble.innerText = user.name?.[0];
      $authLabel.innerText = user.name?.split(" ")?.[0];
      if ($authLabelSecondary) {
        $authLabelSecondary.style.display = "none";
      }
    } else {
      $authLabel.innerText = "Logg inn";
      $authBubble.classList.remove("tv2-menu__auth-bubble--authenticated");
    }
  }
};
