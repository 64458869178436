import { MENU_ELEMENT_ID, MENU_TOP_NAV_ELEMENT_ID } from "./common/constants";
import { handleScroll } from "./scroll/scroll";
import { handleLogin } from "./auth/login";
import { handleMegamenu } from "./megamenu/megamenu";
import {
  hideMobileCategories,
  showMobileCategories,
} from "./api/toggleCategories";
import { onClientHeight } from "./common/clientHeight";
import { onCheckAuth0 } from "./common/checkAuth0";
import { handleAppLogin } from "./auth/appLogin";

const init = () => {
  /*
   * Menu is initially positioned as a block element to avoid CLS on page load.
   * When the page is loaded, we position the menu as a fixed and also add
   * top padding to the body equal to the menu height.
   */

  const $menu = document.getElementById(MENU_ELEMENT_ID);
  const $topNav = document.getElementById(MENU_TOP_NAV_ELEMENT_ID);
  const topNavHeight = Math.ceil($topNav?.getBoundingClientRect().height ?? 0);

  // Note: Since the sportsapp is the only app that has shared login, we differenciate beetween tv2no and sportsapp
  const useJSBridgeLogin = window.MENU_CONFIG.useJSBridgeLogin;  
  const hasWebviewBrigde = window?.TV2JSBridge || window?.webkit?.messageHandlers;

  onClientHeight($menu).then((menuHeight) => {
    $menu?.classList.add("tv2-menu--fixed");
    document.body.style.paddingTop = menuHeight + "px";

    if (document.documentElement.dataset.menuHideOnScroll !== "false") {
      handleScroll();
    }

    handleMegamenu();

    if (useJSBridgeLogin && hasWebviewBrigde) {
      handleAppLogin()
    } else {
      handleLogin();
    }

    /*
     * Client side api for menu customizations (e.g. show/hide categories on mobile) will be handled
     * through custom events with the tv2-menu namespace/prefix.
     */
    document.addEventListener(
      "tv2-menu:show-mobile-categories",
      showMobileCategories
    );
    document.addEventListener(
      "tv2-menu:hide-mobile-categories",
      hideMobileCategories
    );

    /*
     * Let the world know that the menu is ready.
     */
    const eventDetail = {
      $el: $menu,
      menuHeight,
      topNavHeight,
      heightDiff: menuHeight - topNavHeight,
    };
    const readyEvent = new CustomEvent("tv2-menu:ready", {
      detail: eventDetail as any,
    });
    document.dispatchEvent(readyEvent);

    // Register the menuReady as a global variable
    (window as any).TV2 = (window as any).TV2 || {};
    (window as any).TV2.MenuReady = function (dispatchEvent: boolean = false) {
      if (dispatchEvent) {
        return document.dispatchEvent(readyEvent);
      }
      return eventDetail;
    };
  });

  /**
   * Check if Auth0 is ready
   */
  onCheckAuth0($menu).then((auth0IsReady) => {
    (window as any).TV2 = (window as any).TV2 || {};
    (window as any).TV2.Auth0IsReady = function () {
      return {
        isReady: auth0IsReady,
      };
    };
  });
};

if (document.readyState === "complete") {
  init();
} else {
  document.addEventListener("DOMContentLoaded", init);
}
