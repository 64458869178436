/*
 * Check if the user is authenticated with Auth0
 */

const MAX_RETRIES = 10;
const TIME_BETWEEN_RETRIES_MS = 100;

export const onCheckAuth0 = ($menu: HTMLElement | null) => {
  let retries = 0;
  const retry = (): Promise<boolean> => {
    if (!$menu && typeof window.auth0 !== "function" && retries < MAX_RETRIES) {
      retries++;
      return new Promise((resolve) =>
        setTimeout(resolve, TIME_BETWEEN_RETRIES_MS)
      ).then(retry);
    }
    return Promise.resolve(true);
  };
  return retry();
};
