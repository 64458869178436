import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../common/localStorage';

export const setTokenToLocalStorage = (token: string) => {
  const existingLocalStorageToken = getLocalStorageItem('appAuthToken');

  if (token === 'null' || token === '') {
    removeLocalStorageItem('appAuthToken');
    removeLocalStorageItem('appUser');
  } else if ((token && !existingLocalStorageToken) || token !== existingLocalStorageToken) {
    
    setLocalStorageItem('appAuthToken', token);
  }
};
