import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../common/localStorage';
import { trackUserLoginEvent } from './reporting';
import { setTokenToLocalStorage } from './webview';

/**
 * To prevent double login in app, we store the user and token in localstorage
 * This is currently only functional in Sportenapp, so the TV2no-app does use webs
 * Auth0 login for now until we get
 *  a) Support for both JSBridge and webview.messageHandlers in TV2no-app
 *    or
 *  b) Auth0 on the server that clients can share client session
 */
export const handleAppLogin = () => {
  const { userApiUrl } = window.AUTH0_CONFIG;

  const login = async function (reasonToLoginCategory?: string, reasonToLoginName?: string) {
    const data = { reasonToLoginCategory, reasonToLoginName };

    if (window?.webkit?.messageHandlers?.onLoginEvent) {
      window.webkit.messageHandlers.onLoginEvent.postMessage(JSON.stringify(data));
    }

    if (typeof window.TV2JSBridge !== 'undefined' && typeof window?.TV2JSBridge?.onLoginEvent === 'function') {
      window.TV2JSBridge.onLoginEvent(JSON.stringify(data));
    }
  };

  const logout = async function () {
    const user = await getLocalStorageItem('appUser');
    const userId = user?.['https://tv2.no/userId'];
    
    trackUserLoginEvent('Logout Clicked', userId);
    
    // Clear localstorage
    removeLocalStorageItem('appAuthToken');
    removeLocalStorageItem('appUser');

    if (window?.webkit?.messageHandlers?.onLogoutEvent) {
      window.webkit.messageHandlers.onLogoutEvent.postMessage();
    }

    if (typeof window.TV2JSBridge !== 'undefined' && typeof window?.TV2JSBridge?.onLogoutEvent === 'function') {
      window.TV2JSBridge.onLogoutEvent();
    }
  }

  const isAuthenticated = async function () {
    // IOS
    if (window?.webkit?.messageHandlers?.getToken) {
      return (await window.webkit.messageHandlers.getToken.postMessage()) !== '';
    }
    
    // Android
    if (typeof window.TV2JSBridge !== 'undefined' && typeof window.TV2JSBridge?.getToken === 'function') {
      return (await window.TV2JSBridge.getToken()) !== '';
    }
    
    const token = getLocalStorageItem('appAuthToken');

    return !!(token && token !== '');
  };

  const getLoggedInUser = async function () {
    try {
      const token = getLocalStorageItem('appAuthToken');

      const appUser = getLocalStorageItem('appUser');

      if (appUser) {
        return appUser;
      }

      const userResponse = await fetch(userApiUrl, {
        method: 'get',
        credentials: 'include',
        headers: new Headers({
          Authorization: 'Bearer ' + token
        }),
      });

      const user = await userResponse.json();

      setLocalStorageItem('appUser', user, 6);

      return user;
    } catch (e) {
      return;
    }
  };

  const setToken = function (token: string) {
    setTokenToLocalStorage(token);

    // TODO: remove when finished testing
    sendReadyEvent();

    document.dispatchEvent(
      new CustomEvent('tv2-menu:set-token', {
        detail: { 
          isSet: true,
          app: true,
        },
      }),
    );

    // IOS webview demands that a string is returned
    return 'TOKEN_SET';
  };

  const getToken = async function () {
    return getLocalStorageItem('appAuthToken');
  };

  window.TV2 = {
    ...window.TV2,
    login,
    logout,
    isAuthenticated,
    getLoggedInUser,
    getToken,
    setToken,
  };

  const sendReadyEvent = async function () {
    const user = await window?.TV2?.getLoggedInUser();
    const userId = user?.id;
    
    document.dispatchEvent(
      new CustomEvent('tv2-menu:auth0-ready', {
        detail: {
          isReady: true,
          app: true,
          userId
        },
      }),
    );
  }

  sendReadyEvent();
};
