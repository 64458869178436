const track = (event: {
  event: string;
  eventCategory: string;
  eventAction: string;
  userID: string;
  abTestName?: string;
  abgroup?: string;
}) => {
  window.dataLayer?.push(event);
  window.snowplowTracker?.coreTracker?.trackUserLogin({
    userId: event.userID,
    userAction: event.eventAction,
  });
}

export const trackUserLoginEvent = (action: string, userId: string = '') => {
  track({
    event: 'loginEvent',
    eventCategory: 'Login',
    eventAction: action,
    userID: userId,
  });
};
